@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';

@import 'tailwindcss/utilities';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
  .rtl {
    direction: rtl;
  }
}

/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */
[x-cloak=''] {
  display: none;
}

body {
  background-color: white;
  color: black;
}

p {
  margin-bottom: 10px;
}
h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 15px 0 15px 0;
}
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

@media print {
  #agenda-component,
  #agenda-component * {
    visibility: visible; /* Show only the agenda component */
    display: block; /* Show only the agenda component */
  }
  #agenda-component {
    position: absolute;
    left: 0;
    top: 0;
  }
}
